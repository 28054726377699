import React from "react"
import styled from "styled-components"

const StyledDivider = styled.hr`
  margin: 0;
  background-color: rgba(216, 216, 216, 0.5);
  height: 1px;
  border: none;
`

export default function Divider() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <StyledDivider />
        </div>
      </div>
    </div>
  )
}
