import React from "react"
import styled from "styled-components"

import { StyledInput } from "./../components/Input"
import Spacer from "./../components/Spacer"
import { breakpoints } from "../assets/globalStyles"

const StyledSearch = styled(StyledInput)`
  width: 100%;
  @media screen and (min-width: ${breakpoints.md}px) {
    max-width: 330px;
  }
  .input-container {
    position: relative;
    input {
      margin-bottom: 0;
      padding-right: 40px;
    }
    svg {
      position: absolute !important;
      right: 8px;
      top: 8px;
      opacity: 0.4;
    }
  }
`

const Wrapper = styled.div`
  @media screen and (min-width: ${breakpoints.md}px) {
    display: flex;
    align-items: center;
  }
  h5 {
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    color: #1c1c26;
  }
`

export default function SearchBar({
  searchQuery,
  setSearchQuery,
  currentCategory,
}) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Spacer size={16} when={{ md: 16 }} />
          <Wrapper>
            <StyledSearch type="text" fullwidth>
              <div className="input-container">
                <input
                  value={searchQuery}
                  onInput={(e) => setSearchQuery(e.target.value)}
                  type="text"
                  placeholder={`Search ${currentCategory}`}
                  name="search"
                />
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                  <path d="M23.8 21.65l-6.2-6.2A9.74 9.74 0 100 9.73a9.74 9.74 0 0015.22 8.02L21.45 24l2.36-2.35zM2.86 9.73a6.88 6.88 0 1113.77.02 6.88 6.88 0 01-13.77-.02z" />
                </svg>
              </div>
            </StyledSearch>
            <Spacer size={16} when={{ md: 48 }} />
            {!!searchQuery && (
              <h5>{`Showing results for "${searchQuery}" in ${currentCategory}`}</h5>
            )}
          </Wrapper>
        </div>
      </div>
    </div>
  )
}
