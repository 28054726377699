import React, { useState } from "react"
import { graphql } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import CallToAction from "../components/CallToAction"
import PageHeader from "../components/PageHeader"
import CategoryTabs from "../components/CategoryTabs"
import Pagination from "../components/Pagination"
import Spacer from "../components/Spacer"
import BlogPostBox from "../components/BlogPostBox"
import SearchBar from "../components/SearchBar"
import Divider from "../components/Divider"

const StyledNoResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    font-size: 24px;
    line-height: 40px;
    color: #1c1c26;
    font-family: Poppins;
    text-align: center;
    max-width: 600px;
  }
`

export const categoryData = graphql`
  query($postIds: [String]) {
    allWpPost(
      filter: { id: { in: $postIds } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        id
        title
        slug
        date(formatString: "DD/MM/YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  quality: 90
                )
              }
              publicURL
            }
            mimeType
          }
        }
        author {
          node {
            firstName
            lastName
          }
        }
        categories {
          nodes {
            name
          }
        }
      }
    }
    wpPage(title: { eq: "Blog" }) {
      pageHeaderFields {
        headerContent
      }
      seo {
        metaDesc
        title
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      callToActionFields {
        callToAction {
          button {
            label
            link {
              url
            }
          }
          content
        }
      }
    }
    localSearchPosts {
      index
      store
    }
  }
`
export default function Category({
  data: {
    allWpPost: { nodes: allBlogPosts },
    wpPage: {
      seo,
      featuredImage,
      pageHeaderFields: { headerContent },
      callToActionFields: { callToAction },
    },
    localSearchPosts: { index, store },
  },
  pageContext: { totalPages, currentPage, currentCategory, categories },
}) {
  const [searchQuery, setSearchQuery] = useState("")

  let searchResults = useFlexSearch(searchQuery, index, store)

  // Filter out search results that arent in this category because by default it
  // includes all search results
  if (currentCategory.name !== "All" && searchResults) {
    searchResults = searchResults.filter(({ categories: { nodes } }) => {
      const categories = nodes.map(({ name }) => name)

      return categories.includes(currentCategory.name)
    })
  }

  let blogPosts = searchQuery ? searchResults : allBlogPosts

  blogPosts = blogPosts.map(
    ({
      author: {
        node: { firstName, lastName },
      },
      date,
      id,
      slug,
      title,
      featuredImage: { node: imageData },
      categories: { nodes: categoryArray },
    }) => ({
      author: `${firstName} ${lastName}`,
      date,
      id,
      slug,
      title,
      featuredImage: {
        imageType: imageData.mimeType,
        imageUrl: imageData.localFile?.publicURL,
        gatsbyImageData: imageData.localFile?.childImageSharp?.gatsbyImageData,
      },
      categories: categoryArray.map(({ name }) => name),
    })
  )

  return (
    <>
      <Seo
        seo={{
          ...seo,
          featuredImage: featuredImage?.node?.localFile?.publicURL,
        }}
      />
      <PageHeader headerContent={headerContent} showNewsletter={true} />
      <Spacer size={32} when={{ md: 48 }} />
      <CategoryTabs currentCategory={currentCategory} categories={categories} />
      <SearchBar
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        currentCategory={currentCategory.name}
      />
      <Spacer size={24} when={{ md: 40 }} />
      <div className="container">
        <div className="row">
          {blogPosts.length ? (
            <>
              {blogPosts.map(({ id, ...blogPostDetails }) => (
                <div className="col-12 col-md-6" key={id}>
                  <BlogPostBox blogPost={blogPostDetails} />
                  <Spacer size={24} />
                </div>
              ))}
            </>
          ) : (
            <StyledNoResults className="col-12">
              <Spacer size={40} when={{ md: 56 }} />
              <div>
                <StaticImage
                  src="./../assets/images/no-blog-found.png"
                  alt="No blog found"
                  quality={90}
                  placeholder="none"
                  height={300}
                />
              </div>

              <Spacer size={32} when={{ md: 40 }} />
              <h4>
                Sorry, we couldn't find any posts for "{searchQuery}". Try
                searching for something else.{" "}
              </h4>
            </StyledNoResults>
          )}
        </div>
        {totalPages > 1 && !searchQuery && (
          <div className="row">
            <div className="col-12">
              <Pagination
                prevTo={
                  currentPage === 2
                    ? `${currentCategory.slug}`
                    : `${currentCategory.slug}${currentPage - 1}/`
                }
                prevActive={currentPage !== 1 ? 1 : 0}
                nextTo={`${currentCategory.slug}${currentPage + 1}/`}
                nextActive={currentPage !== totalPages ? 1 : 0}
              />
            </div>
          </div>
        )}
      </div>
      <Spacer size={40} when={{ md: 56 }} />
      <Divider />
      <Spacer size={40} when={{ md: 56 }} />
      <CallToAction content={callToAction} />
      <Spacer size={40} when={{ md: 56 }} />
    </>
  )
}
