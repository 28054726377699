import React from "react"
import styled from "styled-components"

import { breakpoints } from "../assets/globalStyles"
import NewsletterSignup from "./NewsletterSignup"
import Spacer from "./Spacer"

const StyledHeader = styled.div`
  background-color: #d42b69;

  h1 {
    color: #ffffff;
    font-family: Poppins;
    font-size: 26px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 16px;
    @media screen and (min-width: ${breakpoints.lg}px) {
      font-size: 34px;
      line-height: 52px;
    }
  }
  p {
    color: #ffffff;
    font-family: Poppins;
    font-size: 20px;
    line-height: 32px;
    @media screen and (min-width: ${breakpoints.lg}px) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  form {
    /* max-width: 400px; */
    p {
      opacity: 1 !important;
    }
    .submit-area {
      p,
      a {
        color: #ffffff !important;
      }
    }
  }
`

export default function PageHeader({ headerContent, showNewsletter }) {
  return (
    <StyledHeader>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Spacer size={0} when={{ md: 24, lg: 72 }} />
          </div>
        </div>
        <div className="row">
          <div className={`col-12 ${showNewsletter ? "col-md-6" : ""}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: headerContent,
              }}
            />
          </div>
          {showNewsletter && (
            <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
              <Spacer size={16} when={{ md: 0 }} />
              <NewsletterSignup context="header" />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <Spacer size={48} when={{ md: 56, lg: 104 }} />
          </div>
        </div>
      </div>
    </StyledHeader>
  )
}
