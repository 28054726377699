import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Button from "./Button"
import Spacer from "./Spacer"
import { breakpoints } from "../assets/globalStyles"

const StyledCallToAction = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  p {
    color: #1c1c26 !important;
    font-family: Poppins;
    font-size: 20px !important;
    line-height: 28px !important;
    text-align: center;
    @media screen and (min-width: ${breakpoints.md}px) {
      font-size: 22px !important;
      line-height: 32px !important;
    }
    @media screen and (min-width: ${breakpoints.lg}px) {
      font-size: 24px !important;
      line-height: 40px !important;
    }
  }
`

export default function CallToAction({ content: { content, button } }) {
  return (
    <StyledCallToAction className="container">
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
      <Spacer size={24} when={{ lg: 48 }} />
      <Button
        to={button.link.url}
        as={Link}
        variant="contained"
        colour="primary"
      >
        {button.label}
      </Button>
    </StyledCallToAction>
  )
}
