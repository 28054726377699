import React, { useState } from "react"
import { Link } from "gatsby"
import { useSpring, animated } from "react-spring"
import styled from "styled-components"

import Spacer from "./Spacer"
import { breakpoints } from "../assets/globalStyles"
import PaginationIcon from "./../assets/icons/pagination-arrow.svg"

const StyledPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (min-width: ${breakpoints.md}px) {
    justify-content: flex-end;
  }
`

const AnimatedPagination = styled(animated(Link))`
  display: inline-block;
  opacity: ${({ active }) => (active ? "1" : "0.4")};
  pointer-events: ${({ active }) => (active ? "auto" : "none")};
`

export default function Pagination({ prevTo, prevActive, nextTo, nextActive }) {
  const [isPrevHovered, setIsPrevHovered] = useState(false)
  const [isNextHovered, setIsNextHovered] = useState(false)

  const prevPaginationAnimation = useSpring({
    transform: isPrevHovered ? "translateX(-7px)" : "translateX(0px)",
    config: {
      tension: 300,
      friction: 15,
    },
  })

  const nextPaginationAnimation = useSpring({
    transform: isNextHovered ? "translateX(7px)" : "translateX(0px)",
    config: {
      tension: 300,
      friction: 15,
    },
  })

  return (
    <StyledPaginationWrapper>
      <AnimatedPagination
        style={prevPaginationAnimation}
        to={prevActive ? prevTo : "/"}
        active={prevActive}
        onMouseEnter={() => {
          setIsPrevHovered(true)
        }}
        onMouseLeave={() => {
          setIsPrevHovered(false)
        }}
      >
        <img src={PaginationIcon} alt="next page" />
      </AnimatedPagination>
      <Spacer size={16} />
      <AnimatedPagination
        onMouseEnter={() => {
          setIsNextHovered(true)
        }}
        onMouseLeave={() => {
          setIsNextHovered(false)
        }}
        to={nextActive ? nextTo : "/"}
        active={nextActive}
        style={nextPaginationAnimation}
      >
        <img
          src={PaginationIcon}
          style={{ transform: "rotate(180deg)" }}
          alt="prev page"
        />
      </AnimatedPagination>
    </StyledPaginationWrapper>
  )
}
