import React, { useState, useLayoutEffect } from "react"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Spacer from "./Spacer"
import { breakpoints } from "../assets/globalStyles"

const StyledBlogPostWrapper = styled(Link)`
  position: relative;
  border: 1px solid #eef1f4;
  background-color: #ffffff;
  display: block;
  @media screen and (min-width: ${breakpoints.md}px) {
    padding-bottom: 110px;
  }
  .image-background {
    background-color: #eef1f4;
    padding: 24px;
    @media screen and (min-width: ${breakpoints.md}px) {
      max-height: 100%;
      height: 380px;
    }
    > img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .blog-post-details {
    background-color: #ffffff;
    padding: 24px;
    width: 100%;
    @media screen and (min-width: ${breakpoints.md}px) {
      position: absolute;
      overflow: hidden;
      bottom: 0;
    }
    h3 {
      color: #1c1c26;
      font-family: Poppins;
      font-size: 20px;
      line-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .blog-post-meta {
      color: #66768e;
      font-family: Roboto;
      font-size: 16px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (min-width: ${breakpoints.md}px) {
        white-space: nowrap;
      }
    }
    .blog-post-categories {
      display: flex;
      flex-wrap: wrap;
      .category {
        display: block;
        background-color: #4f287c;
        padding: 8px 16px;
        color: #fff;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        margin-top: 16px;
        margin-right: 8px;
        white-space: nowrap;
      }
    }
    .view-more {
      display: none;
      @media screen and (min-width: ${breakpoints.md}px) {
        display: inline-flex;
      }
    }
  }
`

export default function BlogPostBox({
  blogPost: {
    author,
    date,
    slug,
    title,
    featuredImage: { imageType, imageUrl, gatsbyImageData },
    categories,
  },
}) {
  const [windowWidth, setWindowWidth] = useState(
    typeof window === "undefined" ? 0 : window.innerWidth
  )

  useLayoutEffect(() => {
    if (typeof window === "undefined") return

    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  const [isHovered, setIsHovered] = useState(false)

  const titleAnimation = useSpring({
    whiteSpace:
      isHovered || windowWidth < breakpoints.md ? "break-spaces" : "nowrap",
  })

  const detailsAnimation = useSpring({
    maxHeight: isHovered || windowWidth < breakpoints.md ? "360px" : "112px",
  })

  return (
    <StyledBlogPostWrapper
      to={`/blog/${slug}/`}
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
    >
      <div className="image-background">
        {imageType === "image/svg+xml" ? (
          <img src={imageUrl} alt={title} />
        ) : (
          <GatsbyImage
            image={gatsbyImageData}
            alt={title}
            objectFit="contain"
            style={{ maxHeight: "100%", height: "100%" }}
            onMouseEnter={() => {
              setIsHovered(true)
            }}
            onMouseLeave={() => {
              setIsHovered(false)
            }}
          />
        )}
      </div>
      <animated.div className="blog-post-details" style={detailsAnimation}>
        <animated.h3 style={titleAnimation}>{title}</animated.h3>
        <Spacer size={8} />
        <animated.p className="blog-post-meta">{`${date} | ${author}`}</animated.p>
        <Spacer size={0} when={{ md: 24 }} />
        <div className="blog-post-categories">
          {categories.map((categoryName) => (
            <span className="category" key={categoryName}>
              {categoryName}
            </span>
          ))}
        </div>
      </animated.div>
    </StyledBlogPostWrapper>
  )
}
